import { Commit } from "vuex";
import { ILoginCredentials } from "@/store/interfaces/auth/common";
import { ILoginResponse } from "@/api/interfaces/ILoginResponse";
import { api } from "@/api/Api";
import { baseStoreActionResponse } from "@/store/interfaces/common";

export async function LoginAction(
  { commit }: { commit: Commit },
  { email, password }: ILoginCredentials
): Promise<baseStoreActionResponse> {
  const {
    success: resSuccess,
    data,
    message: resMessage
  }: ILoginResponse = await api.login({
    email,
    password
  });

  let success = resSuccess;
  let message = resMessage;
  const userType: string = data?.type || "";
  console.log(data);
  if (userType !== "admin") {
    message = message || "Permission denied";
    success = false;
  } else {
    commit("setUserData", data);
    commit("setCredentials", {
      token: data?.token || null,
      refreshToken: data?.refreshToken || null
    });
  }

  return { success, message };
}
