import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse,
  IGApiResponse
} from "@/api/interfaces/IApi";
import {
  ILocationCountry,
  ILocationsStateLists,
  TLocationKeys
} from "@/store/locations";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  ICreateLocationDto,
  IDeleteLocationDto,
  IFetchLocationsDto,
  IFetchLocationsResponse,
  IFetchSelectedLocationsListType,
  IUpdateSelectedLocationsDto,
  IUploadLocationImageDto,
  selectedLocationListType,
  selectedLocationType
} from "@/api/services/locations/locationTypes";
import {
  locationTypeToSingle,
  TPackageLocation,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { ISubregion } from "@/api/services/packages/esim/location-packages/types";

type TGetNestedLocationData = {
  countries: ILocationCountry[];
  subregions: ISubregion[];
};
export interface IGetNestedLocationResponse extends IApiResponse {
  data: TGetNestedLocationData;
}

export interface IFetchSelectedLocationsDto<
  List extends selectedLocationListType,
  Location extends selectedLocationType
> {
  locationType: Location;
  listType: List;
  locationId: number;
}

export const locationsService = {
  async fetchLocationsList<T extends TLocationKeys>(
    request: apiRequestType,
    data: IFetchLocationsDto,
    locationType: T
  ): Promise<IFetchLocationsResponse<T>> {
    const query = queryObjectToUrlString(data);
    const url = `/locations/${locationType}?${query}`;

    const response = await request(url, "get");

    const list = Array.isArray(response.data)
      ? response.data
      : response.data?.results;

    const responseData: ILocationsStateLists[T] = <ILocationsStateLists[T]>list;

    return {
      ...response,
      data: responseData,
      total: response?.data?.total ?? response?.total ?? 0
    };
  },

  async deleteLocation<T extends TPackageLocation>(
    request: apiRequestType,
    { id, locationType }: IDeleteLocationDto<T>
  ) {
    const type = locationTypeToSingle[locationType as "countries" | "regions"];
    return await request(`/locations/remove/${type}/${id}`, "delete");
  },

  async fetchSelectedLocations<
    List extends selectedLocationListType,
    Location extends selectedLocationType
  >(
    request: apiRequestType,
    options: IFetchSelectedLocationsDto<List, Location>
  ): Promise<IGApiResponse<IFetchSelectedLocationsListType[List]>> {
    const { locationId, locationType, listType } = options;

    const query = queryObjectToUrlString({
      list: listType,
      type: locationType
    });

    const response = await request(
      `/locations/selected/list/${locationId}?${query}`,
      "get"
    );

    const data: IFetchSelectedLocationsListType[List] = <
      IFetchSelectedLocationsListType[List]
    >response.data;

    return { ...response, data };
  },

  async uploadLocationImage(
    request: apiRequestFileType,
    payload: IUploadLocationImageDto
  ): Promise<IGApiResponse<{ url: string }>> {
    const res = await request(
      `/locations/upload-image?type=${payload.type}`,
      "post",
      payload.image,
      "file"
    );

    const data = res.data as { url: string };
    return { ...res, data };
  },

  async updateLocation(
    request: apiRequestType,
    payload: ICreateLocationDto,
    locationType: TPackageLocationSingle,
    id?: number,
    method?: "post" | "patch"
  ) {
    const { images, changedLocations, ...basePayload } = payload;
    const resultPayload = { ...basePayload, ...images, changedLocations };

    let url = `/locations/${locationType}`;

    if (method === "patch" && id) {
      url = `/locations/edit-${locationType}/${id}`;
      delete resultPayload.changedLocations;

      changedLocations &&
        this.updateSelectedLocations(request, changedLocations, id);
    }

    return await request(url, method || "post", resultPayload);
  },

  async updateSelectedLocations(
    request: apiRequestType,
    payload: IUpdateSelectedLocationsDto,
    parentId: number
  ) {
    return await request(`/locations/change-selected/${parentId}`, "patch", {
      ...payload,
      changeList: payload.changeList.map(({ id, action }) => ({ id, action }))
    });
  }
};

// Запросить URL: https://verum-api.dev2.4k.com.ua/api/v1/locations/upload-image/204?type=bigImage&location=country

// locationChildType: "subregions"
// locationParentType: "region
// {
//   "changeList": [
//   {
//     "id": 37,
//     "action": "disable"
//   }
// ],
//     "locationChildType": "subregions",
//     "locationParentType": "region"
// }
// Запросить URL: https://verum-api.dev2.4k.com.ua/api/v1/locations/change-selected/67
///https://verum-api.dev2.4k.com.ua/api/v1/locations/region
