<template>
  <router-link to="/accounts">
    <img src="@/assets/img/logo.svg" alt="Verum" />
  </router-link>
</template>

<script>
export default {
  name: "Logo"
};
</script>

<style scoped lang="scss">
a {
  width: 155px;
  display: block;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
