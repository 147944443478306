import { IApiResponse, apiRequestType } from "@/api/interfaces/IApi";
import {
  EditDataPriceModalDto,
  IDataPriceResponse,
  dataPriceFilterSchema,
  dataPriceQuery
} from "@/api/interfaces/data-price/common";
import { IDataPriceData } from "@/store/interfaces/data-price/IDataPrice";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

export const dataPriceService = {
  async fetchDataPrice(
    request: apiRequestType,
    query: dataPriceQuery,
    filter: dataPriceFilterSchema
  ): Promise<IDataPriceResponse> {
    const queryString: string = queryObjectToUrlString(query);
    const queryFilterString: string = queryObjectToUrlString(filter);
    const response = await request(
      `/countries-data-price?${queryString}&${queryFilterString}`,
      "get"
    );
    const data: IDataPriceData = <IDataPriceData>response.data;

    return {
      ...response,
      data
    };
  },

  async editDataPrice(
    request: apiRequestType,
    reqData: EditDataPriceModalDto
  ): Promise<IApiResponse> {
    const isUpdate = reqData?.id;
    const method = isUpdate ? "patch" : "post";
    const url = isUpdate
      ? `/countries-data-price/${reqData?.id}`
      : "/countries-data-price";

    return await request(url, method, reqData);
  }
};
