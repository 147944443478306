import {
  IQuestions,
  questionRequest
} from "@/api/interfaces/questions/IQuestions";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { categoriesQuery } from "@/api/interfaces/accounts/filters";
import {
  apiRequestFileType,
  apiRequestType,
  httpMethodType,
  IApiResponse
} from "@/api/interfaces/IApi";
import {
  IFaqCategoriesData,
  IFaqCategoriesResponse,
  IFaqQuestionsData,
  IFaqQuestionsResponse
} from "@/api/interfaces/faq/common";

export const faqService = {
  async fetchQuestions(
    request: apiRequestType,
    query: categoriesQuery,
    id: number
  ): Promise<IFaqQuestionsData> {
    const queryString: string = queryObjectToUrlString(query);
    const response = await request(
      `/faq/category-questions-admin/${id}?${queryString}`,
      "get"
    );

    const { questions, total }: IFaqQuestionsResponse = <IFaqQuestionsResponse>(
      response.data
    );

    return {
      ...response,
      questions,
      total
    };
  },

  async removeCategory(
    request: apiRequestType,
    id: number
  ): Promise<questionRequest> {
    const response = await request(`/faq/category/${id}`, "delete");
    const data: IQuestions = <IQuestions>response.data;

    return {
      ...response,
      data
    };
  },

  async fetchCategories(
    request: apiRequestType,
    query: categoriesQuery
  ): Promise<IFaqCategoriesData> {
    const queryString: string = queryObjectToUrlString(query);
    const response: IApiResponse = await request(
      `/faq/categories-admin?${queryString}`,
      "get"
    );
    const { categories, total }: IFaqCategoriesResponse = <
      IFaqCategoriesResponse
    >response.data;
    return {
      ...response,
      categories,
      total
    };
  },

  async actionWithCategory(
    request: apiRequestFileType,
    data: ICategories,
    method: httpMethodType,
    file: File,
    id: number | undefined
  ): Promise<IApiResponse> {
    const query = queryObjectToUrlString(data);
    return await request(
      `/faq/category${id ? "/" + id : ""}?${query}`,
      method,
      file,
      "file"
    );
  },

  async addQuestion(
    request: apiRequestType,
    data: IQuestions
  ): Promise<IApiResponse> {
    return await request(`/faq/question`, "post", data);
  },

  async editQuestion(
    request: apiRequestType,
    data: IQuestions,
    id: number
  ): Promise<IApiResponse> {
    return await request(`/faq/question/${id}`, "patch", data);
  },

  async removeQuestion(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    return await request(`/faq/question/${id}`, "delete");
  }
};
