import { createStore } from "vuex";
import { router } from "./router";
import { userModule } from "@/store/users/userModule";
import { oldCountries } from "@/store/oldCountries";
import { phoneCodes } from "@/store/phoneCodes";
import { packages } from "@/store/packages";
import { regions } from "@/store/regions";
import { auth } from "@/store/auth";
import { user } from "@/store/user";
import { usersModule } from "@/store/users";
import { suportedDevicesModule } from "@/store/suported-devices";
import { appModule } from "@/store/app";
import { templates } from "@/store/templates";
import { paymentsModule } from "@/store/payments";
import { aboutProductsModule } from "@/store/about-product";
import { faqModule } from "@/store/faq";
import { locationPackages } from "@/store/locationPackages";
import { esimDirectory } from "@/store/esimDirectory";
import { carrirsCountries } from "@/store/carrirsCountries";
import { carriers } from "@/store/carriers";
import { locations } from "@/store/locations";
import { esimPlansModule } from "@/store/esim-plans";
let overlayTimeout = null;
import { notesModule } from "@/store/notes";
import { esimProfilesModule } from "@/store/esimProfiles";
import { promoCodesModule } from "@/store/promo-codes";
import { dataPriceModule } from "@/store/data-price";
import { balancesModule } from "@/store/balances";

export default createStore({
  state: {
    shownOverlay: false,
    settingsMenuShown: false
  },

  mutations: {
    toggleOverlay: (state, status = !state.shownOverlay) => {
      state.shownOverlay = status;
    },

    settingsMenuToggle(state, status = !state.settingsMenuShown) {
      this.dispatch("toggleOverlay", status);
      state.settingsMenuShown = status;
    }
  },

  actions: {
    toggleOverlay(ctx, status) {
      const delay = 300;
      clearTimeout(overlayTimeout);

      overlayTimeout = setTimeout(() => {
        ctx.commit("toggleOverlay", status);
      }, delay);
    }
  },
  getters: {
    overlayIsShown: state => state.shownOverlay,
    settingsMenuShown: state => state.settingsMenuShown
  },
  modules: {
    router,
    user: userModule,
    oldCountries,
    regions,
    phoneCodes,
    packages,

    //ts
    auth,
    userDetails: user,
    usersModule,
    appModule,
    templates,
    paymentsModule,
    aboutProductsModule,
    faqModule,
    suportedDevicesModule,
    esimPackages: locationPackages,
    esimDirectory,
    countries: carrirsCountries,
    carriers,
    promoCodesModule,
    esimPlansModule,
    esimProfilesModule,
    locations,
    notesModule,
    dataPriceModule,
    balancesModule
  }
});
