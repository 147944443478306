import { useNotificationStore } from "@dafcoe/vue-notification";
const { setNotification } = useNotificationStore();
import { Commit } from "vuex";
let loadingTimeout: ReturnType<typeof setTimeout> | null = null;

function showErrorNotification(message: string): void {
  setNotification({
    message,
    type: "alert",
    showIcon: true,
    dismiss: {
      manually: true,
      automatically: true
    },
    duration: 10000,
    showDurationProgress: true,
    appearance: "light"
  });
}

function showSuccessNotification(message: string): void {
  setNotification({
    message,
    type: "success",
    showIcon: true,
    dismiss: {
      manually: true,
      automatically: true
    },
    duration: 10000,
    showDurationProgress: true,
    appearance: "light"
  });
}

type appModuleType = {
  loading: boolean;
};

export const appModule = {
  state(): appModuleType {
    return {
      loading: false
    };
  },

  getters: {
    appLoading: ({ loading }: appModuleType): boolean => loading
  },

  mutations: {
    setAppLoading(state: appModuleType, loading: boolean) {
      state.loading = loading;
    }
  },

  actions: {
    showErrorNotification(_: any, message: string): void {
      showErrorNotification(message);
    },

    showSuccessNotification(_: any, message: string): void {
      showSuccessNotification(message);
    },

    setAppLoading({ commit }: { commit: Commit }, loading: boolean): void {
      if (!loading) {
        if (loadingTimeout) {
          clearTimeout(loadingTimeout);
        }

        loadingTimeout = setTimeout(() => {
          commit("setAppLoading", loading);
        }, 600);
      } else {
        commit("setAppLoading", loading);
      }
    }
  }
};
