import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse
} from "@/api/interfaces/IApi";
import { ICarrier } from "@/store/carriers";
import {
  IFetchCarriersResponse,
  IUpdateCarrierDto
} from "@/api/services/packages/esim/carriers/types";
import { IObjectKeys } from "@/interfaces/IObjectKeys";

export const carriersService = {
  async fetchCarriers(
    request: apiRequestType,
    countryId: number
  ): Promise<IFetchCarriersResponse> {
    const response = await request(`/carriers/${countryId}`, "get");
    const data = <ICarrier[]>response.data;
    return { ...response, data };
  },

  async deleteCarriers(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    return await request(`/carriers/delete/${id}`, "delete");
  },

  async updateCarriers(
    request: apiRequestType,
    imageRequest: apiRequestFileType,
    data: IUpdateCarrierDto,
    isCreate: boolean
  ): Promise<IApiResponse> {
    const url = isCreate ? "/carriers/add" : "carriers/edit";
    const method = isCreate ? "post" : "put";
    const {
      id,
      active,
      netGens,
      title,
      country,
      image,
      netGensChanges = []
    } = data;

    const reqData: IObjectKeys = { active, name: title };
    if (!isCreate) {
      reqData.id = id;
      reqData.netGensChanges = netGensChanges;
    } else {
      reqData.country = country;
      reqData.netGens = netGens;
      reqData.netGensChanges = netGens
        .filter(({ active }) => active)
        .map(({ networkGeneration, active }) => ({
          id: networkGeneration.id,
          active
        }));

      if (typeof image !== "string") {
        const res = await imageRequest(
          "/carriers/upload-image",
          "post",
          image,
          "file"
        );

        if (res?.data?.url) {
          reqData.image = res.data.url;
        }
      }
    }

    const { success, ...response } = await request(url, method, reqData);

    let message = response.message;

    if (success && typeof image !== "string") {
      if (id) {
        const { success, message: uploadMessage } = await imageRequest(
          `/carriers/upload-image/${id}`,
          "post",
          image,
          "avatar"
        );

        if (!message && !success) {
          message = uploadMessage;
        }
      }
    }

    return {
      ...response,
      success,
      message
    };
  }
};
