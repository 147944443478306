import { IBrands } from "@/api/interfaces/brands/IBrands";
import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";

export const esimProfilesService = {
  async fetchEsimProfilesList(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(`esim/active/byuser/${id}`, "get");
    const data: IBrands = <IBrands>response.data;

    return {
      ...response,
      data
    };
  },


  // TODO: Requests
  async setPackageForUser(
      request: apiRequestType,
      profileId: number,
      packageId: number,
      userId: number
  ): Promise<IApiResponse> {
    const response = await request(`esim/user-esim`, "patch", {
      profileId,
      packageId,
      userId
    });
    return {
      ...response,
      data: response.data
    };
  },

  async addEsimWithPackageForUser(
      request: apiRequestType,
      profileName: string,
      packageId: number,
      userId: number
  ): Promise<IApiResponse> {
    const response = await request(`esim/user-esim`, "post", {
      profileName,
      packageId,
      userId
    });
    return {
      ...response,
      data: response.data
    };
  },

  async deleteEsimForUser(
      request: apiRequestType,
      profileId: number,
      userId: number
  ): Promise<IApiResponse> {
    const response = await request(`esim/user-esim-delete`, "post", {
      userId,
      profileId
    });

    return {
      ...response,
      data: response.data
    };
  }
};
