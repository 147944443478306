import { IEsimDirectoryDefault } from "./../../../../interfaces/esim-directory/common";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  apiRequestFileType,
  IApiResponse
} from "./../../../../interfaces/IApi";
import { ref } from "vue";
import { apiRequestType } from "@/api/interfaces/IApi";
import {
  fetchEsimBundlesDto,
  IBundle,
  IBundlesProviders,
  IDataType,
  INetworkGeneration,
  IPurchaseType,
  IValidity,
  IZone
} from "@/api/services/packages/esim/location-packages/types";
import {
  IBundlesResponse,
  IFetchBundlesResponse,
  IFetchDataTypesResponse,
  IFetchEsimZonesResponse,
  IFetchNetworkGeolocationResponse,
  IFetchRates,
  IFetchValidationTypesResponse
} from "@/api/services/packages/esim/directory/responseTypes";
import { IRate } from "@/api/interfaces/rates/common";

export const esimDirectoryService = {
  // Bundles
  async fetchBundles(request: apiRequestType): Promise<IFetchBundlesResponse> {
    const response = await request("/esim/bundles", "get");
    const data: { bundles: IBundle[] } = <{ bundles: IBundle[] }>response.data;

    return { ...response, data };
  },
  async fetchBundlesByProviderId(
    request: apiRequestType,
    data: fetchEsimBundlesDto
  ): Promise<IBundlesResponse> {
    const { id, ...params } = data;
    const query = queryObjectToUrlString(params);

    return (await request(
      `/providers/bundles/${id}?${query}`,
      "get"
    )) as IBundlesResponse;
  },
  async fetchAllBundles(request: apiRequestType): Promise<IApiResponse> {
    const response = await request("/providers/bundles", "get");
    const data: IBundlesProviders[] = <IBundlesProviders[]>response.data;

    return { ...response, data };
  },

  async synchronizeEsimGoBundles(
    request: apiRequestType
  ): Promise<IApiResponse> {
    return (await request(`/esim-go/sync/bundles`, "get")) as IApiResponse;
  },

  // Data types
  async fetchDataTypes(
    request: apiRequestType
  ): Promise<IFetchDataTypesResponse> {
    const response = await request(
      "/esim-directory/data-types-with-out-packages",
      "get"
    );
    const data: IDataType[] = <IDataType[]>response.data;

    return { ...response, data };
  },

  // Purchase types
  async fetchPurchaseTypes(
    request: apiRequestType
  ): Promise<IFetchDataTypesResponse> {
    const response = await request(
      "/esim-directory/purchase-types-with-out-packages",
      "get"
    );
    const data: IPurchaseType[] = <IPurchaseType[]>response.data;

    return { ...response, data };
  },

  // Validity types
  async fetchValidityTypes(
    request: apiRequestType
  ): Promise<IFetchValidationTypesResponse> {
    const response = await request(
      "/esim-directory/validity-types-with-out-packages",
      "get"
    );
    const data: IValidity[] = <IValidity[]>response.data;

    return { ...response, data };
  },

  // network generations

  async fetchNetworkGenerations(
    request: apiRequestType
  ): Promise<IFetchNetworkGeolocationResponse> {
    const response = await request("/esim-directory/network-gen/active", "get");
    const data: INetworkGeneration[] = <INetworkGeneration[]>response.data;

    return { ...response, data };
  },
  async fetchEsimZones(
    request: apiRequestType
  ): Promise<IFetchEsimZonesResponse> {
    const response = await request("/esim-directory/esim-zones", "get");
    const data: IZone[] = <IZone[]>response.data;

    return { ...response, data };
  },
  async fetchPlans(request: apiRequestType): Promise<IFetchEsimZonesResponse> {
    const response = await request(
      "/esim-directory/plans-with-out-packages",
      "get"
    );
    const data: IZone[] = <IZone[]>response.data;

    return { ...response, data };
  },
  async fetchEsimDirectoryData(
    request: apiRequestType,
    link: string
  ): Promise<IApiResponse> {
    const response = await request(link, "get");
    return { ...response };
  },

  async uploadImage(request: apiRequestFileType, type: string, file: File) {
    const query = queryObjectToUrlString({ type });
    const response = await request(
      `/esim-directory/upload-image/0?${query}`,
      "patch",
      file,
      "file"
    );
    return { ...response };
  },

  async add(
    request: apiRequestType,
    data: IEsimDirectoryDefault,
    link: string
  ) {
    const response = await request(link, "post", data);
    return { ...response };
  },

  async update(
    request: apiRequestType,
    data: IEsimDirectoryDefault,
    link: string,
    id: number
  ) {
    const response = await request(link + "/" + id, "patch", data);
    return { ...response };
  },

  async delete(request: apiRequestType, link: string, id: number) {
    const response = await request(link + "/" + id, "delete");
    return { ...response };
  },

  async fetchEsimRates(request: apiRequestType): Promise<IFetchRates> {
    const response = await request("/esim-directory/rates", "get");
    const data: IRate = <IRate>response.data;
    return { ...response, data };
  },

  async updateEsimRates(
    request: apiRequestType,
    { amount, fee }: IRate
  ): Promise<IFetchRates> {
    const response = await request("/esim-directory/rates/update", "patch", {
      amount,
      fee
    });
    const data: IRate = <IRate>response.data;
    return { ...response, data };
  }
};
