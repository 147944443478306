import { api } from "@/api/Api";
import { IEsimPLans, ILocationPlan } from "@/api/interfaces/esim-plans/common";
import { IBaseQuery } from "@/api/interfaces/IApi";
import {
  IPlanOperator,
  IZone
} from "@/api/services/packages/esim/location-packages/types";
import { editEsimPlanEsimServerDto } from "@/hooks/esim-plans/modules/updatePlanEsimServer";
import { updatePlanStatus } from "@/hooks/esim-plans/modules/updatePlanStatus";
import {
  locationTypeToSingle,
  packageLocationSingleTypes,
  packageLocationTypes,
  TPackageLocation,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { Commit } from "vuex";

export interface IEsimPlansState {
  locationPlans: {
    [packageLocationTypes.countries]: {
      plans: IEsimPLans[];
      total: number;
    };
    [packageLocationTypes.regions]: {
      plans: IEsimPLans[];
      total: number;
    };
  };
}

const initialState = {
  locationPlans: {
    [packageLocationTypes.countries]: {
      plans: [],
      total: 0
    },
    [packageLocationTypes.regions]: {
      plans: [],
      total: 0
    }
  }
};
export const esimPlansModule = {
  state(): IEsimPlansState {
    return { ...initialState };
  },

  getters: {
    esimPlansList: ({ locationPlans }: IEsimPlansState) => {
      return (locationType: packageLocationTypes) => {
        return locationPlans[locationType].plans;
      };
    },
    esimPlansTotal: ({ locationPlans }: IEsimPlansState) => {
      return (locationType: packageLocationTypes) => {
        return locationPlans[locationType].total;
      };
    }
  },

  mutations: {
    setEsimPlans(
      { locationPlans }: IEsimPlansState,
      {
        data,
        locationType
      }: { data: IEsimPLans[]; locationType: TPackageLocation }
    ) {
      locationPlans[locationType].plans = data;
    },
    setEsimPlansTotal(
      { locationPlans }: IEsimPlansState,
      { total, locationType }: { total: number; locationType: TPackageLocation }
    ) {
      locationPlans[locationType].total = total;
    },
    updateOperator(
      { locationPlans }: IEsimPlansState,
      {
        locationType,
        operatorId,
        planId,
        status,
        locationsKey
      }: {
        locationType: TPackageLocationSingle;
        operatorId: number;
        planId: number;
        status: boolean;
        locationsKey: TPackageLocation;
      }
    ) {
      locationPlans[locationsKey]?.plans?.forEach(location =>
        location[`${locationType}Plans`]?.forEach((plan: ILocationPlan) => {
          if (plan.id === planId) {
            plan.planOperators.forEach(operator => {
              if (operator.id === operatorId) {
                operator.active = status;
              }
            });
          }
        })
      );
    }
  },

  actions: {
    async fetchEsimPlans(
      { commit }: { commit: Commit },
      {
        query,
        filter,
        value
      }: {
        query: IBaseQuery;
        filter: string;
        value: any;
      }
    ) {
      const { value: reqValue, locationType } = value;
      const { success, message, data, total } = await api.getEsimPlans(
        query,
        filter,
        reqValue
      );
      if (data) {
        commit("setEsimPlans", { data, locationType });
      }
      if (total) {
        commit("setEsimPlansTotal", { total, locationType });
      }
      return { success, message };
    },

    async updatePlanStatus(
      _: any,
      { id, status, value }: { id: number; status: boolean; value: string }
    ) {
      const { success, message } = await api.updateEsimPlanStatus(
        id,
        status,
        value
      );
      return { success, message };
    },

    async updatePlanOperatorStatus(
      { commit }: { commit: Commit },
      {
        id,
        status,
        value,
        planId,
        locationsKey
      }: {
        id: number;
        status: boolean;
        value: string;
        planId: number;
        locationsKey: string;
      }
    ) {
      const { success, message } = await api.updateEsimPlanOperatorStatus(
        id,
        status,
        value
      );
      // ?.[`${locationTypeToSingle[locationType]}Plans`].planOperators.find((planOperator: IPlanOperator)=> planOperator.id === operatorId).active = status
      commit("updateOperator", {
        locationType: value,
        operatorId: id,
        planId,
        status,
        locationsKey
      });
      return { success, message };
    },

    async changePlanEsimZone(
      _: any,
      { id, zone }: { id: number; zone: IZone }
    ) {
      return await api.changePlanEsimZone(id, zone);
    },
    async changePlanEsimServer(
      _: any,
      { data, value }: { data: editEsimPlanEsimServerDto; value: string }
    ) {
      return await api.changePlanEsimServer(data, value);
    }
  }
};
