import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { Ref } from "vue";
import { sortItem } from "@/enums/main/sortOrders";
import { sorting } from "@/hooks/tables/useTable";
import { Store } from "vuex";
import { ISubregion } from "@/api/services/packages/esim/location-packages/types";
import { ILocationCountry, ILocationRegion } from "@/store/locations";

export enum locationPackagesFilterOptions {
  all = "all",
  empty = "empty",
  filled = "filled"
}

export enum packageLocationTypes {
  countries = "countries",
  regions = "regions"
}

export enum packageLocationSingleTypes {
  country = "country",
  region = "region"
}

export const packageLocationsArray = [
  packageLocationTypes.countries,
  packageLocationTypes.regions
];

export const locationTypeToSingle = {
  [packageLocationTypes.countries]: "country",
  [packageLocationTypes.regions]: "region"
};

export type TPackageLocation = keyof typeof packageLocationTypes;
export type TPackageLocationSingle = keyof typeof packageLocationSingleTypes;
export type TPackageLocationSingleWithoutCountry = "region";
export type TLocationPackagesFilterOption = keyof typeof locationPackagesFilterOptions;
export interface ILocationViewConfig extends ITableViewConfiguration {
  search: string;
  filter: TLocationPackagesFilterOption | string;
}

export interface INestedLocations {
  subregion: ISubregion[];
  region: ILocationRegion[];
}

export interface INestedLocationsType {
  country: ILocationCountry[];
  subregion: ISubregion[];
}

export interface IFetchLocationPackagesParams {
  locationType: Ref<TPackageLocation | null>;
  locationPackagesViewConfig: ILocationViewConfig;
  store: Store<any>;
  loading?: boolean;
}

export interface IFetchLocationPackagesRatesParams {
  locationType: Ref<TPackageLocation | null>;
  store: Store<any>;
}

export interface ILocationPackagesSort extends sorting {
  byLocationName: sortItem;
  byUpdatedAt: sortItem;
}
