import { apiRequestType, IGApiResponse } from "@/api/interfaces/IApi";
import {
  templatesData,
  templatesQuery
} from "@/api/interfaces/templates/common";
import {
  ITemplatesDataResponse,
  ITemplatesTypesDataResponse
} from "@/api/interfaces/templates/ITemplatesResponseData";
import { IUserApplication } from "@/store/interfaces/accounts/IUserAccount";
import {
  ITemplatesData,
  ITypeTemplateData
} from "@/store/interfaces/templates/ITemplateData";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

export const templatesService = {
  async fetchTemplatesData(
    request: apiRequestType,
    query: templatesQuery,
    applicationId: number
  ): Promise<ITemplatesDataResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `/templates/getAllTemplates/${applicationId}?${queryString}`,
      "get"
    );
    const data: ITemplatesData = <ITemplatesData>response.data;

    return {
      ...response,
      data
    };
  },
  async fetchTemplatesTypeData(
    request: apiRequestType,
    applicationId: number
  ): Promise<ITemplatesTypesDataResponse> {
    const response = await request(
      `/templates/types/all/${applicationId}`,
      "get"
    );
    const data: ITypeTemplateData = <ITypeTemplateData>response.data;

    return {
      ...response,
      data
    };
  },
  async addTemplate(request: apiRequestType, reqData: templatesData) {
    const response = await request(
      `/templates/createTemplate`,
      "post",
      reqData
    );
    const data: ITemplatesData = <ITemplatesData>response.data;

    return {
      ...response,
      data
    };
  },
  async updateTemplate(request: apiRequestType, reqData: templatesData) {
    const response = await request(
      `/templates/editTemplate/${reqData.id}`,
      "put",
      reqData
    );
    const data: ITemplatesData = <ITemplatesData>response.data;

    return {
      ...response,
      data
    };
  },
  async deleteTemplate(request: apiRequestType, id: number) {
    const response = await request(`/templates/deleteTemplate/${id}`, "delete");
    const data: ITemplatesData = <ITemplatesData>response.data;

    return {
      ...response,
      data
    };
  },
  async fetchApplications(
    request: apiRequestType
  ): Promise<IGApiResponse<IUserApplication[]>> {
    return (await request("/applications", "get")) as IGApiResponse<
      IUserApplication[]
    >;
  }
};
