import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse,
  IGApiResponse
} from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  esimGoDataType,
  esimProfile,
  esimQrData,
  esimType,
  fetchEsimProfilesDto,
  fetchEsimProviderDto,
  IEsimServerFull,
  providerType
} from "./profiles.types";

export const profilesService = {
  async fetchEsimServers(
    request: apiRequestType,
    id: number,
    year: string
  ): Promise<IGApiResponse<IEsimServerFull[]>> {
    return (await request(
      `esim/server/type/${id}?year=${year}`,
      "get"
    )) as IGApiResponse<IEsimServerFull[]>;
  },

  async fetchEsimProvidersTypes(
    request: apiRequestType
  ): Promise<IGApiResponse<providerType[]>> {
    return (await request("/esim-go/provider-types", "get")) as IGApiResponse<
      providerType[]
    >;
  },

  async fetchEsimTypes(
    request: apiRequestType
  ): Promise<IGApiResponse<esimType[]>> {
    return (await request("/esim/types/all", "get")) as IGApiResponse<
      esimType[]
    >;
  },

  async fetchEsimProfiles(
    request: apiRequestType,
    data: fetchEsimProfilesDto
  ): Promise<IGApiResponse<esimProfile[]>> {
    const { serverId, year, page, perPage, search } = data;
    const query = queryObjectToUrlString({ page, perPage, search });

    return (await request(
      `/esim/list/all/${year}?${query}`,
      "get"
    )) as IGApiResponse<esimProfile[]>;
  },

  async fetchEsimGoProfiles(
    request: apiRequestType,
    data: fetchEsimProfilesDto
  ): Promise<IGApiResponse<esimGoDataType>> {
    const { ...params } = data;
    const query = queryObjectToUrlString(params);

    return (await request(`/esim-go/esims?${query}`, "get")) as IGApiResponse<
      esimGoDataType
    >;
  },

  async getEsimQr(
    request: apiRequestType,
    type: string,
    listId: number
  ): Promise<IApiResponse> {
    return await request(
      `${process.env.VUE_APP_BASE_API_URL}/esim/qr?type=${type}&id=${listId}`,
      "get"
    );
  },

  async importProfile(
    request: apiRequestFileType,
    table: File,
    onProgress?: (progress: number) => void
  ) {
    let progress = 0;
    console.log(table);
    const res = await request(
      `/esim/load-excel?type=esims`,
      "post",
      table,
      "file",
      (evt: ProgressEvent) => {
        const { loaded, total } = evt;
        progress = parseInt(Math.round((loaded / total) * 100).toString());
        onProgress?.(progress);
      }
    );

    return res;
  }
};
