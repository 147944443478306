import { Commit } from "vuex";
import { api } from "@/api/Api";
import { IApiResponse } from "@/api/interfaces/IApi";
import {
  EditDataAbouts,
  IAboutsResponse
} from "@/api/interfaces/about-products/common";
import { IAbout } from "./interfaces/about-product/IAbouts";

export interface aboutProductState {
  aboutsList: IAbout[];
  aboutsTotal: number;
}
export const aboutProductsModule = {
  state(): aboutProductState {
    return {
      aboutsList: [],
      aboutsTotal: 0
    };
  },

  getters: {
    aboutsList: ({ aboutsList }: aboutProductState): IAbout[] => {
      return [...aboutsList];
    },
    aboutsTotal: ({ aboutsTotal }: aboutProductState): number => aboutsTotal
  },

  mutations: {
    setAbouts(state: aboutProductState, abouts: IAbout[]) {
      state.aboutsList = abouts;
    },
    setAboutsTotal(state: aboutProductState, length: number) {
      state.aboutsTotal = length;
    }
  },

  actions: {
    async fetchAboutsList(
      { commit }: { commit: Commit },
      { value }: { value: string }
    ): Promise<IAboutsResponse> {
      const response: IAboutsResponse = await api.fetchAboutsList(value);
      const { data } = response;
      if (data) {
        commit("setAbouts", data);
        commit("setAboutsTotal", data.length);
      }

      return response;
    },
    async editAbouts(
      { commit }: { commit: Commit },
      { value, data }: { value: string; data: EditDataAbouts }
    ): Promise<IApiResponse> {
      return await api.editAbouts(value, data);
    }
  }
};
